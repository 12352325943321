<template>
	<div class="uu-box">
		<div class="logis-tools">
			<div class="tools-head">{{$t('seller.logistics.title')}}</div>
			<div class="tools-list">
				<router-link v-for="(item,index) in toolList" :key="index" :to="item.router"  class="item">
					<div class="item-pic"><img :src="item.iamge" alt=""></div>
					<div class="item-main">
						<div class="name">{{item.title}}</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				toolList:[{
					title: this.$t('seller.logistics.navItem1'),
					iamge:require('@/assets/images/seller/logis-icon1.png'),
					router: { name: 'logisTools' }
				}]
			}
		}
	}
</script>

<style lang="scss" scoped>
.logis-tools {
	padding: 18px 24px;
	.tools-head {
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		color: #111111;
		margin-left: 5px;
	}
	.tools-list {
		.item {
			float: left;
			width: 275px;
			height: 112px;
			border: 1px solid #e6e9ed;
			border-radius: 9px;
			margin-right: 20px;
			margin-top: 15px;
			padding: 20px 10px 15px;
			cursor: pointer;
			box-sizing: border-box;
			.item-pic {
				float: left;
				width: 42px;
				height: 42px;
				img {
					width: 100%;
					height: 100%;
				}
			}
			.item-main {
				margin-left: 62px;
				min-height: 75px;
				.name {
					height: 22px;
					line-height: 22px;
					font-size: 14px;
					color: #111111;
					font-weight: 500;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
		&:after {
			content: "";
			display: block;
			clear: both;
		}
	}
}
</style>
